.card.fullscreen {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1050; /* Adjust as needed */
    background-color: white;
    overflow: hidden;
    border-radius: 0px;
    margin: 0%;
    padding: 0%;
  }
  
  .fullscreen-table {
    height: calc(100vh - 60px); /* Adjust based on the header height */
    overflow: auto;
    width: 100%;
  }
  
  button {
    font-size: 20px;
    padding: 10px;
  }
  